html.webshop--ishomepage .main--margintop {
  margin-top: 0;
  width: 100%;
}
html.webshop--ishomepage .usps__yogamatonline {
  background-color: #626262;
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.12);
}
html.webshop--ishomepage .category__blocks {
  background-color: #56455e;
  padding: 50px 0 40px 0;
}
html.webshop--ishomepage .category__blocks .categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  position: relative;
}
html.webshop--ishomepage .category__blocks .categoryitem:hover, html.webshop--ishomepage .category__blocks .categoryitem:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
html.webshop--ishomepage .category__blocks .categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
}
html.webshop--ishomepage .category__blocks .categoryitem .categoryitem__image img {
  border-radius: 6px;
}
html.webshop--ishomepage .category__blocks .categoryitem__title {
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  color: #fff;
  padding: 20px 10px;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  font-weight: 700;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 13px;
  text-shadow: 0 2px 4px #000;
  -webkit-transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
html.webshop--ishomepage .category__blocks .categoryitem:nth-child(7), html.webshop--ishomepage .category__blocks .categoryitem:nth-child(8) {
  display: none;
}
html.webshop--ishomepage .product_listing {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 0;
  max-width: 1800px;
  margin: 0 auto;
}
html.webshop--ishomepage main .threecol {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 576px) {
  html.webshop--ishomepage .categorygrid {
    margin-bottom: 0;
  }
  html.webshop--ishomepage .product_listing .col-lg-4 {
    width: 100%;
  }
}
@media only screen and (max-width: 576px) and (min-width: 320px) {
  html.webshop--ishomepage main {
    margin-top: 0;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 1200px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  html.webshop--ishomepage .product_listing .col-lg-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}