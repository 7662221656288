footer {
  background: #73627a;
}
footer #ondermenu {
  margin-top: 50px;
  padding: 20px 0 0 0;
}
footer #ondermenu .row--cols > div {
  margin-bottom: 20px;
  font-size: 14px;
}
footer #ondermenu .col-collapse .collapse {
  display: inherit;
}
@media (max-width: 767px) {
  footer #ondermenu {
    padding: 20px 0;
  }
  footer #ondermenu .row--cols > div {
    margin-bottom: 0;
  }
  footer #ondermenu .col-collapse ul {
    margin-bottom: 0;
  }
  footer #ondermenu .col-collapse .collapse {
    display: none;
  }
  footer #ondermenu .col-collapse .collapse.show {
    display: block;
  }
  footer #ondermenu .col-collapse .title {
    text-transform: uppercase;
  }
  footer #ondermenu .col-collapse .title:hover {
    cursor: pointer;
  }
  footer #ondermenu .col-collapse .title .fa-angle-up,
  footer #ondermenu .col-collapse .title[aria-expanded=true] .fa-angle-down {
    display: none;
  }
  footer #ondermenu .col-collapse .title {
    margin-bottom: 10px;
  }
  footer #ondermenu .col-collapse .title[aria-expanded=true] .fa-angle-up {
    display: block;
  }
  footer #ondermenu .col-collapse .navbar-toggler {
    display: inline-block;
    padding: 0;
  }
  footer #ondermenu .bg-secondary {
    background-color: transparent !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  footer #ondermenu .btn-purple {
    background-color: #E3B7BD;
    border-color: #E3B7BD;
    color: #fff !important;
    border-color: #E3B7BD;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  footer #ondermenu .btn-purple:hover,
  footer #ondermenu .btn-purple:focus {
    background-color: #fcccd3;
    border-color: #fcccd3;
    text-decoration: none;
    color: #fff;
  }
  footer #ondermenu .row {
    margin-right: 0;
    margin-left: 0;
  }
}
footer #footer__widget__1 img {
  max-width: 150px;
  width: 100%;
  height: auto;
}
footer .footer__widget {
  color: #ffffff;
}
footer .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
footer .footer__widget ul li {
  display: table-row;
}
footer .footer__widget ul li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #ffffff;
}
footer .footer__widget ul li a {
  color: #ffffff;
}
footer .footer__widget a {
  color: #ffffff;
}
footer .footer__widget .form-control {
  border: none;
}
footer .footer__widget .widget--kiyohwidget {
  width: 270px;
}
footer .footer__widget .bg-secondary {
  margin-top: -50px;
}
@media (max-width: 992px) {
  footer .footer__widget .bg-secondary {
    margin-top: 0;
  }
}
footer h5 {
  display: block;
  margin: 0 0 10px;
  color: #ffffff;
}
@media (max-width: 565px) {
  footer .bottom__logos {
    text-align: left;
  }
  footer .bottom__logos img {
    max-width: 43px;
    margin-right: 15px;
  }
}

.bottom__logos {
  padding-bottom: 0px;
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.bottom__logos img {
  max-height: 50px;
  width: auto;
  max-width: 55px;
  margin-right: 15px;
}
.bottom__logos .payment__logos {
  display: inline;
}
.bottom__logos .payment__logos img {
  max-height: 30px;
  margin-bottom: 10px;
}

.bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
.bottomnav ul li {
  display: inline-block;
}
.bottomnav ul li + li:before {
  content: "-";
  padding: 0 6px;
  display: inline-block;
}

.grecaptcha-badge {
  visibility: hidden;
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 1 !important;
}