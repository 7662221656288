.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}
aside
{
  border-bottom:1px solid #ddd;
  padding-bottom: 20px;
}
.widget-image
{
  margin-bottom: 20px;
}
/* productfilter(s) */
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  padding-bottom: 0;
}
.productfilter__activelabel
{
  background-color: #fff;
  padding: 5px 25px 5px 10px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: inline-block;
  position: relative;
}
.productfilter__activeremove:before
{
  content: "x";
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  color: #d35f5f;
  position: absolute;
  right: 5px;
  top: 3px;
}
.productfilter:last-child
{
  padding-bottom: 0;
}
#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter
{
  border-bottom:0;
}
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color:#333;
  font-size: 14px;
}
#filterSideNav ul li
{
  border-bottom:0!important;
}
.productfilter-title
{
  padding:0;
  margin: 10px 0;
  display: block;
  color:#333;
  font-size: 14px;
  font-weight: 600;
}
.productfilter-title[data-toggle="collapse"]:after
{
  content: "\f106";
  font-family: FontAwesome;
  float: right;
  font-size: 16px;
  position: relative;
  top: -3px;
}

.productfilter-title[data-toggle="collapse"].collapsed:after
{
  content: "\f107";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: -5px 0 0 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-content: flex-start;
  border-top: 1px solid #eee;
}
.productfilter--optionlist li input
{
  margin-top: 6px;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
  margin-bottom: 0px;
  font-size: 14px;
}
.productfilter--select
{
  width: 100%;
  background-color:#fff;
  padding: 5px;
  border-color: #ccc;
  border-radius: .25rem;
  color: #495057;
  font-weight: 300;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
a.collapsebtn[aria-expanded="false"]:before{
  content:"Filter";
}

a.collapsebtn[aria-expanded="true"]:before{
  content:"Filter inklappen";
}
.twocol .productsgrid .col-lg-4,
.twocol .productsgrid .col-sm-6
{
  flex: 0 0 100%;
  max-width: 100%;
}
.widget-inlinemultiplereviews--bgwhite
{
  background-color: #fff;
  padding: 20px;
}
.widget-inlinereview__comment
{
  background-color: #eee;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview__comment:after
{
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 30px;
  border-left: 10px solid transparent;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
}
.widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
  .widget-inlinereview__comment > *:before
, .widget-inlinereview__comment > *:after
{
  display: inline-block;
  content: '"';
}
.widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview__more
{
  margin-top: 10px;
}

.widget-fullwidthimage a
{
  display: block;
  text-decoration: none;
}
.widget-fullwidthimage img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.category__banner
{
  background-color: #5e5045;
}
.category__banner h2,
.category__banner p
{
  color:#fff;
}

.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}

.usps__yogamatonline
{
  padding:10px 0 8px 0;
  background-color: #626262;
  font-size: 13px;
  min-height: 35px;
}
.usps__yogamatonline .fa
{
  color: #e0b7bd;
}
.usps__yogamatonline .fa-0:before
{
  content: "\f01e";
}
.usps__yogamatonline .fa-1:before
{
  content: "\f0d1";
}
.usps__yogamatonline .fa-2:before
{
  content: "\f07a";
}
.usps__yogamatonline .fa-3:before
{
  content: "\f023";
}
.usps__yogamatonline .fa-4:before
{
  content: "\f095";
}
.usps__yogamatonline a
{
  color: #ffffff;
}
.usps__yogamatonline .col-sm-12 div
{
  align-self: center;
}
.usp_cycle div {
    display: none;
}
@media (min-width: 320px) and (max-width: 480px)
{
  .usp_cycle div
  {
    font-size: 13px;
  }
}

.homepage__blocks
{
  padding:25px 0;
}
.homepage__blocks .brands
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.homepage__blocks .brands img
{
  padding-left: 15px;
}
.homepage__blocks .brands a span {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 14px;
    text-decoration: underline;
    color: #56455e;
}
.usp-column {
    padding: 5px 20px;
}
.usp-column ul {
    padding-left: 0;
}
.usp-column ul li {
  list-style-type: none;
  line-height: 2.2em;
  font-size: 13px;
}
.usp-column ul li .fa-check
{
  margin-right: 7px;
  border-radius: 10px;
  padding: 3px;
  color: #fff;
  font-size: 10px;
}
.usp-column .fa-check {
    background: #e0b7bd;
}

.widget__recentblogs
{
  background: url(../web/img/bg-wall.jpg) no-repeat bottom center;
  padding: 80px 0px;
  margin-bottom: 30px;
}
.widget__recentblogs .container-fluid
{
  max-width:1800px;
  margin:0 auto;
}
.widget__recentblogs a p
{
  color:#333333;
}
.widget__recentblogs a.card
{
  transition: all .2s ease-in-out 0s;
}
.widget__recentblogs a.card:hover
{
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  -moz-box-shadow: 0 0 20px rgba(0,0,0,.2);
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,.2);
}
.widget__recentblogs a:hover
{
  text-decoration: none;
  color:#333333;
}
.widget__recentblogs h2
{
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  margin-top: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
/* widget faq */
.widget-faq .card
{
  margin-bottom: 5px;
  border: 0;
}
.widget-faq .card-header
{
  background-color: #f0f0f0;
}

.widget-faq .card .card-header
{
  padding:0;
  margin-bottom: 0;
}
.widget-faq .btn-link
{
  color:#50505c;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
}
.widget-faq .card-header .btn:after
{
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
  font-size: 14px;
}
.widget-faq .card-header .btn.collapsed:after
{
  content: "\f067";
}
.page--blog aside
{
  background-color: #fff;
  padding:20px;
  border-radius: .25rem;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  border: 1px solid rgba(0,0,0,.125);
}
.page--blog #sidenav > ul > li.active
{
  margin-top: 20px;
}

.widget-innerlinks .badge-light:hover,
.widget-innerlinks .badge-light:focus
{
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.widget__bloglist a:hover
{
  text-decoration: none!important;
}
.widget__bloglist h5
{
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

/* banner */
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.widget-login .wrdauth__loginbutton
{
  display: block;
}

  .twocol .row:after
, .twocol .col-md-6:after
{
  content: "";
  clear: both;
  display: block;
}

.twocol .col-md-6
{
  width: 50%;
  float: left;
}

@media(max-width: 768px)
{
  .twocol .col-md-6
  {
    float: none;
    width: 100%;
  }
}

.categorylist__list,
.subcategorylist__list,
.subsubcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.categorylist__list li a:hover,
.categorylist__list li a:focus
{
  color:#333;
}
.subcategorylist__list,
.subsubcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  font-size: 15px;
}

.categorylist__listitem
{
  list-style-type: none;
  padding: 1px 0;
}
.categorylist__list > li.categorylist__listitem
{
  display: none;
}
.categorylist__list > ul > li > a 
{
  margin-bottom: 10px;
}
.categorylist__list > li.categorylist__listitem.selected
{
  display: block;
  font-size: 21px;
  font-weight: 400;
}
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  list-style-type: none;
  padding: 0 0 0 10px;
}
li.selected > .subcategorylist__list,
li.selected > .subsubcategorylist__list
{
  display: block;
}
.subcategorylist__listitem.selected > a,
.subsubcategorylist__listitem.selected > a
{
  text-decoration:underline;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink,
.subsubcategorylist__listitemlink
{
  color: #333;
}

.widget-tagcloud
{
/*  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 380px;*/
}
.widget-tagcloud ul
{
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 10px -7.5px;
  overflow: hidden;
  height: 240px;
}
.widget-tagcloud ul.show
{
  height: auto;
}
.widget-tagcloud li
{
  display: table-row;
}
.widget-tagcloud li:before
{
  content: '\f105';
  width: 1.5em;
  font-size: 16px;
  line-height: 24px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #333;
}
.widget-tagcloud .less
{
  display: none;
}
.widget-tagcloud .showMore,
.widget-tagcloud .showLess
{
  cursor: pointer;
}

/* ========================================================================

   Verlanglijstje

*/

/* toon verlanglijstje verwijdering alleen op verlanglijst pagina */
html.yogamatonline--verlanglijstje .site__content
{
  margin-top: 1.5rem;
}
html:not(.yogamatonline--verlanglijstje) .yogamatonline--only-verlanglijstje
{
  display:none;
}
.yogamatonline__removefromlist label
{
  cursor:pointer;
}
.yogamatonline__removefromlist .webshop-product__favorite
{
  visibility: hidden;
  width:0;
}
.yogamatonline__removefromlist__text
{
  margin-left:5px;
}

.top-indicator {
    right: 0;
    top: 1rem;
    bottom: inherit;
    left: inherit;
    margin-right: 1rem;
}
.overflow {
    position: relative;
    overflow: hidden;
}
.zoom img {
    transition: all 0.2s linear;
}
.zoom:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


html:not(.webshop--iscategorypage) .ordering-container,
html.webshop--ishomepage .ordering-container
{
  display: none;
}
html.webshop--istagpage .ordering-container
{
  display: block;
}