@charset "UTF-8";
.webshop-checkout__form h3 {
  font-weight: 400;
}
.webshop-checkout__form .hp-checkout__userdata {
  width: 100%;
  max-width: 620px;
  display: flex;
  flex-wrap: wrap;
}
.webshop-checkout__form .wh-form__fieldgroup {
  flex: 1 0 100%;
}
.webshop-checkout__form .wh-form__fields {
  flex: 1 0 0px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=skw_ordertype] .wh-form__fields {
  display: flex;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=skw_ordertype] .wh-form__fields .wh-form__fieldline {
  flex: 0 0 125px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=skw_ordertype] .wh-form__fields .wh-form__fieldline.wh-form__fieldline--nowrap {
  flex-wrap: unset;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"] {
  flex: 0 0 310px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_gender] .wh-form__fieldline {
  min-width: 60px;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"] .wh-form__label,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"] .wh-form__label,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"] .wh-form__error,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"] .wh-form__error {
  width: 1px;
  min-width: 0;
  white-space: nowrap;
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"],
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] {
  flex: 0 0 calc(100% - 310px);
}
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"] .wh-form__label,
.webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] .wh-form__label {
  min-width: 0;
}
.webshop-checkout__form #orderform-customercomment {
  max-width: 500px;
  height: 70px;
}
@media (max-width: 767px) {
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_email],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"] {
    flex: 0 0 calc(50% - 5px);
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=billing_orgname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=billing_vatnr] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.country"] .wh-form__pulldown,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=shipping_receivername] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=shipping_orgname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.country"] .wh-form__pulldown {
    max-width: 100%;
  }
  .webshop-checkout__form .hp-checkout__userdata {
    justify-content: space-between;
  }
}
@media (max-width: 500px) {
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"] {
    flex: 0 0 calc(100% - 110px);
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.street"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.street"] input {
    max-width: 100%;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] {
    flex: 0 0 100px;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.nr_detail"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.nr_detail"] input {
    max-width: 100%;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_email],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone],
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_dateofbirth] {
    flex: 0 0 100%;
  }
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_firstname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.zip"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="billing_address.city"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_email] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.zip"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for="shipping_address.city"] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_contact_phone] input,
  .webshop-checkout__form .wh-form__fieldgroup[data-wh-form-group-for=wrd_dateofbirth] input {
    max-width: 100%;
  }
}

.webshop-checkout__userdata {
  margin-bottom: 20px;
}

.webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]), .webshop-checkout__form .wh-form__fieldgroup--checkbox {
  margin-bottom: 10px;
}
.webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]) > label, .webshop-checkout__form .wh-form__fieldgroup--checkbox > label {
  display: none !important;
}
.webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]) .wh-form__radio, .webshop-checkout__form .wh-form__fieldgroup--radiogroup:not([data-wh-form-group-for=skw_ordertype]) .wh-form__checkbox, .webshop-checkout__form .wh-form__fieldgroup--checkbox .wh-form__radio, .webshop-checkout__form .wh-form__fieldgroup--checkbox .wh-form__checkbox {
  position: relative;
  top: 1px;
}

.hp-orderstatusblock {
  display: flex;
  padding-bottom: 20px;
}
.hp-orderstatusblock__image {
  font-size: 10px;
  flex: 0 0 50px;
}
.hp-orderstatusblock__image img {
  max-width: 38px;
}
.hp-orderstatusblock__image .fa {
  font-size: 26px;
}
.hp-orderstatusblock__image .fa-check {
  color: #5cb85c;
}

.webshop-listcart__productstable {
  width: 100%;
}

.webshop-listcart__products {
  margin-bottom: 20px;
}
.webshop-listcart__products tr.webshop-listcart__productrow > td {
  vertical-align: top;
  padding: 5px;
}
.webshop-listcart__products tr.webshop-listcart__productrow {
  border-bottom: 1px solid #ddd;
}
.webshop-listcart__products tr.webshop-listcart__producttotalrow {
  background: #f9f9f9;
}
.webshop-listcart__products .webshop-listcart__controls .webshop-listcart__productamountcell {
  padding-top: 5px;
  width: 90px;
  border-bottom: 1px solid #ddd;
}
.webshop-listcart__products .webshop-listcart__optionlabel {
  font-style: italic;
}
.webshop-listcart__products .webshop-listcart__optionlabel:after {
  content: ": ";
}
.webshop-listcart__products .webshop-listcart__delete {
  color: #337ab7;
  cursor: pointer;
}
.webshop-listcart__products .webshop-listcart__delete .fa {
  display: block;
  margin-top: 9px;
}
.webshop-listcart__products .webshop-listcart__delete:hover {
  color: inherit;
}
.webshop-listcart__products .webshop-listcart__linetotalcell, .webshop-listcart__products .webshop-listcart__totalcell {
  text-align: right;
  white-space: nowrap;
}
.webshop-listcart__products .webshop-listcart__totallabel:after {
  content: ": ";
}
.webshop-listcart__products .listcart__producttotaldiscountcell {
  color: #5cb85c;
  font-weight: 600;
}
.webshop-listcart__products .webshop-listcart__productdiscountimagecell::before,
.webshop-listcart__products .webshop-listcart__orderdiscountimagecell::before,
.webshop-listcart__products .webshop-listcart__productdiscount .webshop-listcart__imagecell::before,
.webshop-listcart__products .webshop-listcart__orderdiscount .webshop-listcart__imagecell::before {
  content: "\f02b"; /* fa-tag */
  font: normal normal normal 24px FontAwesome;
  padding-left: 15px;
}
.webshop-listcart__products .webshop-listcart__productdiscountrow, .webshop-listcart__products .webshop-listcart__orderdiscountrow {
  color: #5cb85c;
  font-style: italic;
}
.webshop-listcart__products .webshop-listcart__discounttotalrow {
  color: #5cb85c;
}

.webshop-checkout__paymentmethods,
.webshop-checkout__shippingmethods {
  margin-bottom: 20px;
}
.webshop-checkout__paymentmethods > .wh-form__label,
.webshop-checkout__shippingmethods > .wh-form__label {
  display: none;
}
.webshop-checkout__paymentmethods input[type=radio],
.webshop-checkout__shippingmethods input[type=radio] {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
  top: auto !important;
}
.webshop-checkout__paymentmethods .wh-form__fieldline,
.webshop-checkout__shippingmethods .wh-form__fieldline {
  border-top: 1px solid #ddd;
  align-items: stretch;
  flex-direction: row;
  position: relative;
  vertical-align: bottom;
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__pulldown,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__pulldown {
  position: absolute;
  width: 155px;
  left: 57px;
  top: -2px;
  padding: 3px 5px;
  -webkit-appearance: menulist;
}
@media (max-width: 550px) {
  .webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__pulldown,
  .webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__pulldown {
    position: inherit;
    left: 10px;
  }
}
.webshop-checkout__paymentmethods .wh-form__fieldline > *,
.webshop-checkout__shippingmethods .wh-form__fieldline > * {
  padding: 0;
  min-height: 40px;
  display: flex;
}
.webshop-checkout__paymentmethods .wh-form__fieldline > *.webshop-checkout__costs,
.webshop-checkout__shippingmethods .wh-form__fieldline > *.webshop-checkout__costs {
  display: block;
  position: absolute;
  padding-top: 5px;
  top: 0;
  right: 5px;
  pointer-events: none;
}
.webshop-checkout__paymentmethods .wh-form__fieldline label,
.webshop-checkout__shippingmethods .wh-form__fieldline label {
  margin-bottom: 0;
  cursor: pointer;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input + label,
.webshop-checkout__shippingmethods .wh-form__fieldline input + label {
  flex: 0 0 30px;
  position: relative;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input + label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline input + label:before {
  display: inline-block;
  content: "";
  width: 30px;
  position: absolute;
  top: 7px;
  left: 5px;
  display: inline-block;
  font-family: fontAwesome;
  color: #5cb85c;
  font-size: 26px;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input:checked + label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline input:checked + label:before {
  content: "\f00c";
  background: none;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input + label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline input + label:before {
  background: url(../../web/img/radiobutton.png) no-repeat 1px -2px;
  width: 30px;
  background-size: 24px;
  height: 30px;
}
.webshop-checkout__paymentmethods .wh-form__fieldline input:checked ~ *, .webshop-checkout__paymentmethods .wh-form__fieldline:hover > *,
.webshop-checkout__shippingmethods .wh-form__fieldline input:checked ~ *,
.webshop-checkout__shippingmethods .wh-form__fieldline:hover > * {
  background-color: #ecf9ff;
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__subfield,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__subfield {
  position: relative;
  top: -33px;
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__optiondata > label,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__optiondata > label {
  width: 100%;
  height: 100%;
  max-height: 60px; /* IE fix, else IE has render problems with height on mouseover */
  padding: 5px 5px 5px 45px;
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 13px;
}
@media (max-width: 450px) {
  .webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__optiondata > label,
  .webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__optiondata > label {
    max-width: 150px;
    line-height: 16px;
  }
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__optiondata > label:before,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__optiondata > label:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 45px;
  height: 39px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 85%;
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__subfield,
.webshop-checkout__shippingmethods .wh-form__fieldline .wh-form__subfield {
  margin-left: 50px;
}

.webshop-checkout__shippingmethods .wh-form__optiondata > label:before {
  background-image: url(../../web/img/shipping/postnl.png);
}

.webshop-checkout__shippingmethods .wh-form__optiondata > label[for*=SHIPPING_HOME]:before {
  background-image: url(../../web/img/shipping/postnl.png);
}

.webshop-checkout__shippingmethods .wh-form__optiondata > label[for*=HP_SHIPPING_AUDICIEN]:before {
  background-image: url(../../web/img/shipping/afhalen.png);
}

@media screen and (min-width: 362px) and (max-width: 450px) {
  .webshop-checkout__paymentmethods .wh-form__fieldline:first-child {
    max-height: 40px;
  }
}
.webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__pulldown {
  position: absolute;
  width: 135px;
  left: 43px;
  top: -5px;
  padding: 3px 5px;
  -webkit-appearance: menulist;
}
@media screen and (min-width: 362px) and (max-width: 450px) {
  .webshop-checkout__paymentmethods .wh-form__fieldline .wh-form__pulldown {
    position: relative;
    left: 57px;
    top: -3px;
  }
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label:before {
  background-image: url(../../web/img/payment/ideal-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_APPLEPAY]:before {
  background-image: url(../../web/img/payment/apple-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_GOOGLEPAY]:before {
  background-image: url(../../web/img/payment/google-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_IDEAL]:before {
  background-image: url(../../web/img/payment/ideal-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_MISTERCASH]:before {
  background-image: url(../../web/img/payment/bancontact-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_BELFIUS]:before {
  background-image: url(../../web/img/payment/belfius-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_INGHOME]:before {
  background-image: url(../../web/img/payment/ing-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_KBC]:before {
  background-image: url(../../web/img/payment/kbc-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_KLARNA]:before {
  background-image: url(../../web/img/payment/klarna_payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_MAESTRO]:before {
  background-image: url(../../web/img/payment/maestro-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_MASTERCARD]:before {
  background-image: url(../../web/img/payment/mastercard-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_PAYPAL]:before {
  background-image: url(../../web/img/payment/paypal-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_DIRECTBANK]:before {
  background-image: url(../../web/img/payment/sofort-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_VISA]:before {
  background-image: url(../../web/img/payment/visa-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_GIROPAY]:before {
  background-image: url(../../web/img/payment/giropay-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_GOOD4FUN]:before {
  background-image: url(../../web/img/payment/good4fun-payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_PREWIRE]:before {
  background-image: url(../../web/img/payment/bankoverschrijving_payment.png);
}
.webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_EPS]:before {
  background-image: url(../../web/img/payment/eps-payment.png);
}

html.page--isshopde .webshop-checkout__paymentmethods .wh-form__optiondata > label[for*=_PREWIRE]:before {
  background-image: url(../../web/img/payment/vorkasse.png);
}

/*  .webshop-listcart__linetotal:before
, .webshop-checkout__ordertotal:before
, .webshop-listcart__producttotal:before
, .webshop-listcart__discounttotal:before
, .webshop-listcart__baskettotal:before
, .webshop-checkout__cost:before
{
  content: "€ ";
}*/
.webshop-checkout__ordertotals,
.webshop-listcart__productlinetotalcell,
.webshop-listcart__productdiscountlinetotalcell,
.webshop-listcart__orderdiscountlinetotalcell {
  text-align: right;
  white-space: nowrap;
  font-weight: 400;
}

.webshop-checkout__ordertotals h3 {
  margin-bottom: 0;
}

.webshop-listcart__totallabel {
  margin-right: 10px;
}

.hp-checkout__addcode {
  height: 0;
  display: flex;
  overflow: hidden;
  transition: height 300ms;
}

input.webshop-checkout__addcouponcode {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  font: inherit;
  font-size: 12px;
  padding: 5px 10px;
  max-width: 145px;
  -webkit-appearance: none;
}

.webshop-checkout__addcouponbutton {
  border-radius: 0 3px 3px 0;
}

.webshop-account__meta {
  margin-bottom: 20px;
}
.webshop-account__meta .webshop-account__orderid {
  font-weight: bold;
}

.webshop-account__invoices ul {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}
.webshop-account__invoices ul li {
  display: block;
  padding-bottom: 3px;
  padding-left: 22px;
  position: relative;
}
.webshop-account__invoices ul li:before {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  content: "\f1c1";
  font: normal normal normal 24px FontAwesome;
  font-size: 16px;
}

.webshop-account__address {
  display: flex;
  margin-bottom: 20px;
}
.webshop-account__address > div {
  flex: 1 1 50%;
}
.webshop-account__address .webshop-account__fullorderaddress {
  padding-left: 20px;
}
@media (max-width: 450px) {
  .webshop-account__address {
    display: block;
  }
  .webshop-account__address .webshop-account__fullorderaddress {
    padding-top: 10px;
    padding-left: 0;
  }
}

.webshop-account__ordercontents {
  max-width: 100%;
  overflow-x: auto;
}
.webshop-account__ordercontents table {
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin: 0;
}
.webshop-account__ordercontents th, .webshop-account__ordercontents td {
  vertical-align: top;
  text-align: left;
  padding-right: 10px;
}
.webshop-account__ordercontents th.right, .webshop-account__ordercontents td.right {
  text-align: right;
  padding-left: 10px;
  padding-right: 0;
}
.webshop-account__ordercontents .webshop-account__productlineprice, .webshop-account__ordercontents .webshop-account__productlinevat,
.webshop-account__ordercontents .webshop-account__productlinetotal,
.webshop-account__ordercontents tfoot .webshop-account__productlinetitle,
.webshop-account__ordercontents .webshop-account__titletotal,
.webshop-account__ordercontents .webshop-account__total,
.webshop-account__ordercontents .webshop-account__labelprice,
.webshop-account__ordercontents .webshop-account__labelvat,
.webshop-account__ordercontents .webshop-account__labeltotal {
  text-align: right;
  padding-left: 10px;
  padding-right: 0;
}
.webshop-account__ordercontents .webshop__price {
  white-space: nowrap;
}
.webshop-account__ordercontents .webshop__price:before {
  content: "€";
  display: inline-block;
  padding-right: 2px;
}
.webshop-account__ordercontents .webshop__vat {
  white-space: nowrap;
}
.webshop-account__ordercontents .webshop__vat:after {
  content: "%";
  display: inline-block;
  padding-right: 2px;
}
.webshop-account__ordercontents .webshop-account__productlineimage > img {
  width: 60px;
  height: auto;
}
.webshop-account__ordercontents .webshop-account__productlinetitle {
  width: 100%;
  min-width: 100px;
}
.webshop-account__ordercontents thead th {
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 3px;
  white-space: nowrap;
}
.webshop-account__ordercontents tfoot td {
  border-top: 1px solid #ddd;
}
.webshop-account__ordercontents tfoot tr:last-child td {
  font-weight: bold;
}
.webshop-account__ordercontents td {
  padding-top: 15px;
  padding-bottom: 15px;
}
.webshop-account__ordercontents td.webshop-account__productlinetotal, .webshop-account__ordercontents td.webshop-account__total {
  padding-left: 25px;
}
@media (max-width: 550px) {
  .webshop-account__ordercontents thead th:nth-child(1),
  .webshop-account__ordercontents tbody td:nth-child(1) {
    padding-right: 5px;
  }
  .webshop-account__ordercontents thead th:nth-child(2),
  .webshop-account__ordercontents tbody td:nth-child(2) {
    padding-right: 0;
    width: 0;
  }
  .webshop-account__ordercontents .webshop-account__productlineimage > img {
    display: none;
  }
  .webshop-account__ordercontents td.webshop-account__productlinetotal,
  .webshop-account__ordercontents td.webshop-account__total {
    padding-left: 10px;
  }
}

@media (max-width: 993px) {
  html.webshop--ischeckoutpage #sidebar,
  html.webshop--ischeckoutdonepage #sidebar {
    display: none;
  }
}
.webshop-checkout__addcouponbutton {
  border-radius: 0 3px 3px 0;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.checkout-codelist {
  padding: 0px;
  margin: 0 0 20px 0;
  list-style-type: none;
}

.codelist__line {
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: bottom;
  align-items: center;
}

.checkout-codelist .codelist__line:nth-last-child(1) {
  border-bottom: 1px solid #ddd;
}

.codelist__title {
  position: relative;
  display: inline-block;
  color: #5cb85c;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 45px;
  min-height: 30px;
}

.codelist__title::before {
  position: absolute;
  left: 5px;
  top: 5px;
  content: "\f02b"; /* fa-tag */
  font: normal normal normal 24px FontAwesome;
  color: #5cb85c;
}

.codelist__removecode {
  display: inline-block;
  top: 5px;
}

.codelist__removecode::before {
  content: "\f014"; /* fa-thrash-o */
  font: normal normal normal 18px FontAwesome;
  padding-top: 4px;
  padding-right: 10px;
  color: #337ab7;
  cursor: pointer;
}

@media (min-width: 992px) {
  .hp-listcart__mobiletitlerow {
    display: none;
  }
}
.webshop-listcart__ordertotalrow .webshop-listcart__totallabel {
  display: none;
}
.webshop-listcart__ordertotalrow .webshop-listcart__total:before {
  content: "Totaal incl. BTW";
  font-weight: bold;
}

.webshop-listcart__totallabel:after {
  content: ": ";
}

.webshop-listcart__row--inactive {
  text-decoration: line-through;
}

.webshop-listcart__total {
  display: inline-block;
  min-width: 45px;
}

@media (max-width: 993px) {
  html.webshop--ischeckoutpage #sidebar {
    display: none;
  }
  .webshop-listcart__productrow, .webshop-listcart__productdiscountrow {
    display: block;
    position: relative;
    padding-left: 70px;
    min-height: 100px;
    overflow: hidden;
  }
  .webshop-listcart__productrow .webshop-listcart__imagecell, .webshop-listcart__productdiscountrow .webshop-listcart__imagecell {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
  .webshop-listcart__productrow .webshop-listcart__titlecell, .webshop-listcart__productdiscountrow .webshop-listcart__titlecell {
    display: block;
  }
  .webshop-listcart__productrow .webshop-listcart__productamountcell, .webshop-listcart__productdiscountrow .webshop-listcart__productamountcell {
    display: block;
    float: left;
    width: 34%;
  }
  .webshop-listcart__productrow .webshop-listcart__deletecell, .webshop-listcart__productdiscountrow .webshop-listcart__deletecell {
    display: block;
    float: left;
    width: 65%;
    text-align: center;
  }
  .webshop-listcart__productrow .webshop-listcart__productamountcell + .webshop-listcart__deletecell, .webshop-listcart__productdiscountrow .webshop-listcart__productamountcell + .webshop-listcart__deletecell {
    width: 33%;
  }
  .webshop-listcart__productrow .webshop-listcart__linetotalcell, .webshop-listcart__productdiscountrow .webshop-listcart__linetotalcell {
    display: block;
    float: left;
    width: 33%;
    text-align: right;
  }
}