@media (min-width: 992px) {
  .scroll-down #header,
  .scroll-up #header {
    transform: none;
  }
  .scroll-up #sticky,
  .scroll-down #sticky {
    position: sticky;
    top: 0;
    z-index: 1020;
    background-color: #73627a !important;
  }
}
@media (max-width: 992px) {
  .scroll-down #header {
    transform: translate3d(0, -100%, 0);
  }
  .scroll-up #header {
    transform: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .scroll-up #header .usps__yogamatonline {
    display: none;
  }
  .scroll-up #header #header {
    background-color: #73627a !important;
  }
}
header {
  background-color: #fff;
  transition: 0.2s;
}
header .topbar {
  background-color: #f4f4f4;
  color: #333333;
}
header .topbar .topnav {
  margin-bottom: 0;
}
header .topbar .topnav a {
  padding: 0 0.5rem;
  color: #333333;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .topleftpanel .logo {
  display: inline-block;
  margin: 15px 0;
  transition: 0.2s;
}
header .topleftpanel .logo > img {
  height: auto;
  width: 260px;
}
header .toprightpanel i {
  color: #73627a !important;
  font-size: 22px;
  position: relative;
  top: 4px;
}
header .toprightpanel a {
  color: #73627a !important;
}
header .toprightpanel a.dropdown-toggle:after {
  display: none;
}
header .toprightpanel #wishlist-item .badge {
  top: -6px;
  z-index: 1;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
header .toprightpanel .list-inline-item .dropdown-menu.show {
  min-width: 100px;
}
header .toprightpanel .badge {
  background: #E3B7BD !important;
  position: absolute;
  margin-left: -10px;
  top: -8px;
  right: -12px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px;
  font-family: arial, helvetica, sans-serif;
}
header .toprightpanel .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  padding: 0 0 0 10px;
}
header .toprightpanel .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (max-width: 992px) {
  header {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  header.bg-light {
    background-color: #ffffff !important;
  }
}
@media (max-width: 992px) {
  header .logo {
    margin: 10px 0;
  }
  header .logo > img {
    max-width: 230px;
  }
}
@media (max-width: 767px) {
  header .logo {
    margin: 10px 0;
  }
  header .logo > img {
    max-width: 230px;
  }
}
@media (max-width: 565px) {
  header .container {
    max-width: 100%;
  }
  header .logo > img {
    max-width: 150px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header .topleftpanel {
    text-align: center;
  }
  header #mainbar .col-auto {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbar {
  padding-top: 2px;
  padding-bottom: 2px;
}
@media (max-width: 1200px) {
  .navbar #mainnav .toplevelmenu__container li .nav-link {
    padding: 0.5rem;
  }
}

.navbar-brand {
  font-size: 1rem;
}

.navbar-toggler {
  border: none;
}

#topsearchform {
  position: absolute;
  right: 5px;
  top: 25px;
  -webkit-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  max-width: 250px;
  overflow: hidden;
}
#topsearchform * {
  outline: none;
  box-sizing: border-box;
}
#topsearchform .search__wrapper {
  position: relative;
  top: -1px;
}
#topsearchform .search__wrapper .search__field {
  width: 36px;
  height: 36px;
  color: transparent;
  padding: 0.35em 30px 0.35em 0;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  background-color: #73627a !important;
  transition: all 0.3s ease-in-out;
}
#topsearchform .search__wrapper .search__field:focus {
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  background-color: #ffffff !important;
  width: 30vw;
  padding-left: 5px;
  color: #73627a !important;
  cursor: default;
}
#topsearchform .search__wrapper .search__field:focus ~ .search__icon {
  color: #73627a !important;
  cursor: pointer;
  pointer-events: auto;
}
#topsearchform .search__wrapper .search__field::-webkit-input-placeholder, #topsearchform .search__wrapper .search__field:-moz-placeholder, #topsearchform .search__wrapper .search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  opacity: 1;
}
#topsearchform .search__wrapper .search__field::-webkit-input-placeholder[style*=hidden], #topsearchform .search__wrapper .search__field:-moz-placeholder[style*=hidden], #topsearchform .search__wrapper .search__field:-ms-input-placeholder[style*=hidden] {
  color: #000;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
#topsearchform .search__wrapper .search__icon {
  position: absolute;
  background-color: transparent;
  top: 0;
  color: #ffffff !important;
  right: 0;
  width: 36px;
  height: 36px;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
}
#topsearchform .search__wrapper .search__icon i {
  font-size: 22px;
}

#mainnav {
  width: 100%;
  max-height: 42px;
  /* Dim the screen on hover of menu item and focus on dropdown  */
}
#mainnav .toplevelmenu__container {
  width: 100%;
}
#mainnav .toplevelmenu__container .nav-link {
  color: #fff;
  font-weight: 600;
}
#mainnav .toplevelmenu__container .nav-link i {
  color: #333333;
}
#mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 10;
}
@media (min-width: 1200px) {
  #mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
    display: block;
  }
}
#mainnav .toplevelmenu__container .toplevelmenu .dropdown-toggle::after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  border: 0;
  font-size: 14px;
  position: relative;
  top: 4px;
}
#mainnav .toplevelmenu__container .toplevelmenu .has-megamenu {
  position: static;
}
#mainnav .toplevelmenu__container li {
  display: inline-block;
}
#mainnav .toplevelmenu__container li a {
  color: #fff;
}
#mainnav .toplevelmenu__container li:hover li a:hover {
  color: #fff;
  text-decoration: underline;
}
#mainnav .dropdown-menu {
  position: absolute;
  width: 100%;
  padding: 1rem 1rem 2rem 1rem;
  margin: -1px 0 0 0;
  background-color: #73627a !important;
}
#mainnav .dropdown-menu p.normal {
  display: none;
}
#mainnav .dropdown-menu ul {
  display: block;
  float: left;
  padding-left: 0;
  margin-right: 20px;
  margin-bottom: 0;
  min-width: 18%;
}
#mainnav .dropdown-menu ul li {
  display: block;
  color: #fff;
  position: relative;
  margin-left: 0;
}
#mainnav .dropdown-menu ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
}
#mainnav .dropdown-menu ul li strong, #mainnav .dropdown-menu ul li b {
  font-size: 18px;
}
@media all and (min-width: 991px) {
  #mainnav .dropdown:after {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* transition: opacity .15s ease;*/
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  #mainnav .dropdown.show .nav-link {
    position: relative;
    z-index: 12;
  }
  #mainnav .dropdown.show:after {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    position: fixed;
  }
}
@media (min-width: 1200px) {
  #mainnav .dropdown:hover:after {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    position: fixed;
  }
}