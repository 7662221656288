@charset "UTF-8";
@import "~dompack/browserfix/reset.css";
@import "~sidebarjs/dist/sidebarjs.css";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("@mod-ecoyogi/webdesigns/yogamatonline/node_modules/slick-carousel/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("@mod-ecoyogi/webdesigns/yogamatonline/node_modules/slick-carousel/slick/fonts/slick.eot");
  src: url("@mod-ecoyogi/webdesigns/yogamatonline/node_modules/slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("@mod-ecoyogi/webdesigns/yogamatonline/node_modules/slick-carousel/slick/fonts/slick.woff") format("woff"), url("@mod-ecoyogi/webdesigns/yogamatonline/node_modules/slick-carousel/slick/fonts/slick.ttf") format("truetype"), url("@mod-ecoyogi/webdesigns/yogamatonline/node_modules/slick-carousel/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slick-prev:before {
  color: #E3B7BD;
}

.slick-next:before {
  color: #E3B7BD;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f0f0f0;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}

body, main.site-wrapper {
  min-height: 100vh;
}

.wh-anchor {
  scroll-margin-top: 100px;
}

.main--margintop {
  margin-top: 1.5rem;
}

.page--blogitem main.main--margintop,
.page--rtd main.main--margintop {
  margin-top: 0;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: 20px;
}
.page--blogitem .main--margintop,
.page--rtd .main--margintop {
  margin-top: 0;
}
.page--blogitem .headerimage,
.page--rtd .headerimage {
  background: #fff;
  text-align: center;
  margin: -20px;
}
.page--blogitem .headerimage img,
.page--rtd .headerimage img {
  border-radius: 0.25rem;
}

.site__content {
  flex: 1 1 auto;
  position: relative;
}

.yogamatonline__cornerimage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .yogamatonline__cornerimage {
    width: 280px;
    height: auto;
  }
}

.bg-dark {
  background-color: #626262 !important;
}

.bg-light {
  background-color: #f0f0f0 !important;
}

.bg-extralight {
  background-color: #f9f9f9 !important;
}

.bg-primary {
  background-color: #73627a !important;
}
.bg-primary h2.heading2,
.bg-primary p {
  color: #fff;
}

.bg-primary-dark {
  background-color: #56455e !important;
}
.bg-primary-dark h2.heading2,
.bg-primary-dark p {
  color: #fff;
}

.bg-secondary {
  background-color: #E3B7BD !important;
}

.bg-green {
  background-color: #98b13a !important;
}

.rounded-lg {
  border-radius: 15px;
}

.form-control {
  font-weight: 300;
}

main > nav {
  margin-top: -10px;
}

main > *:last-child {
  margin-bottom: 50px;
}

main .pageimage {
  margin-bottom: 20px;
}
main .pageimage > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.contents--spaceright {
  padding-right: 30%;
  position: relative;
}

.contents__imageright {
  width: calc(30% - 20px);
  min-height: 20px;
  position: absolute;
  top: 7px;
  right: 0;
}
.contents__imageright > img {
  width: 100%;
  height: auto;
}

.webshop-checkout {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .contents--spaceright {
    padding-right: 0;
  }
  .contents__imageright {
    display: none;
  }
}
.rating-stars {
  background-image: url(web/img/stars-review-grey.png);
  display: inline-block;
  height: 23px;
  position: relative;
  width: 117px;
  clear: both;
}
.rating-stars > .inner {
  background-image: url(web/img/stars-review-gold.png);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pagetags {
  margin: 30px 0;
}
.pagetags ul {
  list-style-type: none;
  margin: 0 0 0 -6px;
  padding: 0;
}
.pagetags ul li {
  display: inline-block;
  margin-bottom: 6px;
  margin-left: 6px;
}
.pagetags ul li a {
  display: inline-block;
  color: #333;
  border-color: #fff;
  background-color: #fff;
  padding: 6px 12px;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 0.25rem;
}
.pagetags ul li a:hover {
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

@media (max-width: 576px) {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb {
  background: none;
  padding-left: 0;
  margin-bottom: 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumb li {
  display: inline-block;
}
.breadcrumb a,
.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #999;
  font-size: 14px;
}

.contentarea {
  position: relative;
  z-index: 0;
}

button {
  cursor: pointer;
}

.btn {
  cursor: pointer;
  font-weight: 300;
  white-space: normal;
}

.btn-lg {
  padding: 0.7rem 1.3rem;
  font-size: 1rem;
}

.btn-primary,
.btn-green {
  background-color: #98b13a;
  border-color: #98b13a;
  color: #fff !important;
  border-color: #98b13a;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-outline-primary {
  color: #98b13a;
  border-color: #98b13a;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-green:hover,
.btn-green:focus {
  background-color: #acc942;
  border-color: #acc942;
  text-decoration: none;
  color: #fff;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: #98b13a;
  border-color: #98b13a;
  text-decoration: none;
  color: #fff;
}

.btn-primary:active,
.btn-primary.active,
.btn-primary.active:hover,
.btn-green:active,
.btn-green.active,
.btn-green.active:hover {
  background-color: #5dbfec;
  border-color: #3498c7;
}

.btn-secondary {
  background-color: #E3B7BD;
  border-color: #E3B7BD;
  color: #fff;
  border-color: #E3B7BD;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #fcccd3;
  border-color: #fcccd3;
  text-decoration: none;
}

.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.active:hover {
  background-color: #5dbfec;
  border-color: #3498c7;
}

.btn-purple {
  background-color: #73627a;
  border-color: #73627a;
  color: #fff !important;
  border-color: #73627a;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-purple:hover,
.btn-purple:focus {
  background-color: #8c7694;
  border-color: #8c7694;
  text-decoration: none;
  color: #fff;
}

.btn-success {
  background-color: rgb(68, 187, 110);
  border-color: rgb(68, 187, 110);
}

.btn-info {
  background-color: #4185d6;
}

.btn-d-none {
  display: none;
}

.card-body {
  padding: 1rem;
}

aside {
  margin: 0 0 20px;
}
aside.widget-login {
  background: #fff;
  margin: 0 0 20px;
  padding: 20px;
  overflow: hidden;
}
aside .aside__title {
  font-size: 21px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  line-height: 1.1;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.1;
}

aside.usps .aside__title {
  display: block;
  padding: 20px 20px 10px;
  font-size: 26px;
  line-height: 1.1;
  text-align: center;
}

aside.usps ul li:first-child {
  position: relative;
  list-style-type: none;
  padding: 8px 20px 8px 50px;
}

aside.usps span.aside__title small {
  text-transform: none;
  color: #333333;
  font-size: 21px;
  font-weight: 400;
}

aside.usps ul {
  display: block;
  margin: 0;
  padding: 0;
}

aside.usps ul li:first-child {
  font-weight: 600;
  font-size: 16px;
}

aside.usps ul li {
  position: relative;
  list-style-type: none;
  padding: 8px 20px 8px 50px;
}

aside.usps ul li b {
  font-size: 16px;
}

aside.usps ul li a {
  color: #333333;
}

aside.usps ul li:before {
  content: "\f00c";
  position: absolute;
  left: 20px;
  font-family: fontAwesome;
  color: #75b325;
  font-size: 21px;
  top: 10px;
}

aside .mm-rating,
.reviewtotals .mm-rating {
  display: block;
  font-size: 21px;
  margin: 7px 0 -8px -2px;
  font-weight: 600;
}

aside .mm-recensie,
.reviewtotals .mm-recensie {
  background: #333333;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 5px;
}

aside .mm-rating-txt,
aside .mm-rating-txt a,
.reviewtotals .mm-rating-txt,
.reviewtotals .mm-rating-txt a {
  display: inline-block;
  font-size: 14px;
  margin: 0;
}

.mydialog {
  background: white;
  padding: 20px;
}
.mydialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mydialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.mydialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

#sidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#sidenav > ul {
  margin-top: -5px;
}
#sidenav li {
  display: block;
}
#sidenav a {
  display: block;
  text-decoration: none;
  line-height: 150%;
  color: #333333;
}
#sidenav a:hover {
  text-decoration: underline;
}
#sidenav > ul > li > a {
  font-size: 21px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.1;
}
#sidenav > ul > li > ul > li li {
  padding-left: 15px;
}
#sidenav li > ul a {
  padding: 5px 0 3px 15px;
  position: relative;
  color: #58585a;
  font-size: 15px;
}
#sidenav li > ul a:before {
  content: "\f105";
  display: inline-block;
  position: absolute;
  font-family: fontAwesome;
  color: inherit;
  font-size: 16px;
  top: 5px;
  left: 0;
}

.contents__catpath {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
  color: #a89895;
}

.contents__catpathelt {
  display: inline-block;
}

.contents__catpathelt + li::before {
  padding: 0 5px;
  color: #ccc;
  content: "/ ";
}

.summary .pagedescription {
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 30px;
}
.summary .webshop-blog__item .card-title {
  font-size: 1.5rem;
}
.summary .webshop-blog__item a {
  color: #333333;
}
.summary .webshop-blog__item a:hover {
  text-decoration: none;
}

.webshop-account__orders {
  width: 100%;
  margin: 0 0 20px;
  border-bottom: 1px solid #f0f0f0;
}
.webshop-account__orders .webshop-account__ordertotal:before {
  display: inline-block;
  content: "€";
  padding-right: 5px;
}
.webshop-account__orders td, .webshop-account__orders th {
  padding: 5px 20px 5px 0;
  white-space: nowrap;
}
.webshop-account__orders .webshop-account__ordertotal {
  text-align: right;
  width: 100px;
}
.webshop-account__orders .webshop-account__orderrow:nth-child(2n-1) td {
  background-color: #fafafa;
}

.webshop-account__fullbillingaddress .webshop-account__ordertotal:before {
  content: "€ ";
}

.btn-default {
  color: #999;
  border-color: #ccc;
  transition: all 0.5s;
}

.btn-default:hover,
.btn-default:focus {
  background-color: #d9534f;
  color: #fff;
  border-color: #d9534f;
}

.btn-outline-light:hover,
.btn-outline-light:focus {
  background-color: #fff;
  color: #50505c !important;
}

.submenu:not(.d-none) {
  display: none;
}

/* mobile left sidemenu: */
#mainSideNav,
#filterSideNav {
  visibility: hidden;
  border-top: 0;
}
#mainSideNav .close-btn,
#filterSideNav .close-btn {
  display: block;
  float: right;
  width: 42px;
  height: 41px;
  display: block;
  padding: 5px 5px;
}
#mainSideNav .close-btn:before,
#filterSideNav .close-btn:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #73627a !important;
  display: block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  transform: translateY(-1px) rotate(45deg);
}
#mainSideNav .close-btn:after,
#filterSideNav .close-btn:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  margin-top: -2px;
  background: #73627a !important;
  display: block;
  -webkit-transform: translateY(-1px) rotate(-90deg);
  transform: translateY(-1px) rotate(-45deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#mainSideNav .webshop-search input,
#mainSideNav .input-group > .input-group-append > .btn,
#filterSideNav .webshop-search input,
#filterSideNav .input-group > .input-group-append > .btn {
  border-radius: 0;
}
#mainSideNav ul,
#filterSideNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainSideNav [sidebarjs-container],
#mainSideNav [sidebarjs-backdrop],
#filterSideNav [sidebarjs-container],
#filterSideNav [sidebarjs-backdrop] {
  transition: all 0.3s ease;
}
#mainSideNav [sidebarjs-container],
#filterSideNav [sidebarjs-container] {
  background: #f0f0f0 !important;
  overflow-y: auto;
}
#mainSideNav [sidebarjs-container] .nav-link,
#filterSideNav [sidebarjs-container] .nav-link {
  font-size: 14px;
}
#mainSideNav [sidebarjs-container] a,
#mainSideNav [sidebarjs-container] h3,
#filterSideNav [sidebarjs-container] a,
#filterSideNav [sidebarjs-container] h3 {
  color: #333333;
}
#mainSideNav.sidebarjs--is-visible,
#filterSideNav.sidebarjs--is-visible {
  visibility: visible;
}
#mainSideNav .has-submenu,
#filterSideNav .has-submenu {
  position: relative;
}
#mainSideNav .has-submenu .nav-link,
#filterSideNav .has-submenu .nav-link {
  display: block;
  margin-right: 42px;
  font-size: 14px;
}
#mainSideNav .has-submenu > .submenu-toggle,
#filterSideNav .has-submenu > .submenu-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: block;
  float: right;
  width: 43px;
  height: 43px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  box-sizing: border-box;
}
#mainSideNav .has-submenu > .submenu-toggle:last-child,
#filterSideNav .has-submenu > .submenu-toggle:last-child {
  border-top: none;
}
#mainSideNav .has-submenu > .submenu-toggle:before,
#filterSideNav .has-submenu > .submenu-toggle:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #73627a !important;
  display: block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#mainSideNav .has-submenu > .submenu-toggle:after,
#filterSideNav .has-submenu > .submenu-toggle:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  background: #73627a !important;
  display: block;
  -webkit-transform: translateY(-1px) rotate(-90deg);
  transform: translateY(-1px) rotate(-90deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#mainSideNav .has-submenu > .submenu-toggle:hover,
#filterSideNav .has-submenu > .submenu-toggle:hover {
  cursor: pointer;
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:before,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:after,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:after {
  -webkit-transform: translateY(-1px) rotate(-360deg);
  transform: translateY(-1px) rotate(-360deg);
  opacity: 0;
}
#mainSideNav .has-submenu .has-submenu > .nav-link,
#filterSideNav .has-submenu .has-submenu > .nav-link {
  padding: 0.5rem 1rem !important;
}
#mainSideNav ul li,
#filterSideNav ul li {
  border-bottom: 1px solid #ccc;
  margin-top: -1px;
}
#mainSideNav ul li ul li,
#filterSideNav ul li ul li {
  border: none;
}
#mainSideNav ul.active li,
#filterSideNav ul.active li {
  border: none;
}
#mainSideNav ul li.active,
#filterSideNav ul li.active {
  border-top: 0;
}
#mainSideNav ul.submenu,
#filterSideNav ul.submenu {
  margin-left: 0.5rem;
}
#mainSideNav ul.submenu .nav-link,
#filterSideNav ul.submenu .nav-link {
  padding: 0.2rem 1rem;
}
#mainSideNav .btn-link,
#mainSideNav .btn-link:active,
#mainSideNav .btn-link:focus,
#mainSideNav .btn-link:hover,
#filterSideNav .btn-link,
#filterSideNav .btn-link:active,
#filterSideNav .btn-link:focus,
#filterSideNav .btn-link:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 5px;
}

@media (max-width: 576px) {
  .webshop--isproductpage main {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  #mainSideNav {
    display: none;
  }
}
.z-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-3 {
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
}